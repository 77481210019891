import {test_cpf, validar_cns} from "./functions_shared";

$(function () {
    handle_citizen_mother_name_change();
    handle_citizen_father_name_change();
    $('#submit_citizen').on('click', function() {
        $('#modal-citizen .close').click();
    });
    $('#cleam_form').on('click', function() {
        $('#new_citizen')[0].reset();
        $('#submit_citizen').prop('disabled',false);
    });
    $('.input_citizen_cpf').change(function () {
        if (!test_cpf(this.value.replace(/[^0-9]/g, ""))){
            alert('O CPF está incorreto!')
        }
        if ($(this).val() != "" && $(this).val() != null ){
            verify_cpf_cns(this.value.replace(/[^0-9]/g, ""),null)
        }
    });
    $('.input_citizen_cns').change(function () {
        if (!validar_cns($(this).val())){
            alert('O CNS está incorreto!')
        }
        if ($(this).val() != "" && $(this).val() != null ){
            verify_cpf_cns(null, $(this).val())
        }
    });
    $('.check_mother_name').click( function () {
        if ($('.check_mother_name').is(':checked')) {
            $('.input_citizen_mother_name').prop("readonly", true).val('NAO INFORMADO')
        } else {
            $('.input_citizen_mother_name').prop("readonly", false).val("");
        }
    });
    $('.check_father_name').click( function () {
        if ($('.check_father_name').is(':checked')) {
            $('.input_citizen_father_name').prop("readonly", true).val('NAO INFORMADO');
        } else {
            $('.input_citizen_father_name').prop("readonly", false).val("");
        }
    });
    function handle_citizen_mother_name_change() {
        if ($('.input_citizen_mother_name').val() == 'NAO INFORMADO') {
            $('.check_mother_name').prop('checked', true);
            $('.input_citizen_mother_name').prop("readonly", true)
        } else {
            $('.check_mother_name').prop('checked', false);
            $('.input_citizen_mother_name').prop("readonly", false)
        }
    }
    function handle_citizen_father_name_change() {
        if ($('.input_citizen_father_name').val() == 'NAO INFORMADO') {
            $('.check_father_name').prop('checked', true);
            $('.input_citizen_father_name').prop("readonly", true)
        } else {
            $('.check_father_name').prop('checked', false);
            $('.input_citizen_father_name').prop("readonly", false)
        }
    }

});

function verify_cpf_cns(cpf,cns){
    $.ajax({
        url: '/load_resources/search_cpf_cns',
        type: 'GET',
        dataType: 'script',
        data: cns ? {q: {cns_eq: cns}} : {q: {cpf_eq: cpf}},
        success: function(data) {
            data = JSON.parse(data);
            if (data){
                alert('Paciente já cadastrado no sistema!');
                $('.submit_citizen').prop('disabled', true)
            }else{
                $('.submit_citizen').prop('disabled', false)
            }
        }
    })
}


