$(function () {
  $("input[id=patient_record_address_attributes_zip_code]").keyup(function() {
    var cep=$(this).val()
    var url = "/utils/search-cep?cep=" + cep;
    if (cep.length >= 6){
      $.getJSON(url, function( data ) {
      }).done(function(data) {
        $("#patient_record_address_attributes_street").val(data['logradouro'])

        
        $('#patient_record_address_attributes_neighborhood_id option')
            .filter(function(index) { return $(this).text() === data['bairro'];
         }).attr('selected', true);

        $('#patient_record_address_attributes_neighborhood_id').trigger("chosen:updated");

        
        $('#patient_record_address_attributes_county_id option')
            .filter(function(index) { return $(this).text() === data['localidade'];
         }).attr('selected', true);

        $('#patient_record_address_attributes_county_id').trigger("chosen:updated");


        $('#patient_record_address_attributes_federative_unit_id option')
            .filter(function(index) { return $(this).text() === data['uf'] })
            .attr('selected', true);

        $('#patient_record_address_attributes_federative_unit_id').trigger("chosen:updated");

      }).fail(function() {

      });
    }
  });
});


$(function () {
  $("input[id=notifying_unit_address_attributes_zip_code]").keyup(function() {
    var cep=$(this).val()
    var url = "/utils/search-cep?cep=" + cep;
    if (cep.length >= 6){
      $.getJSON(url, function( data ) {
      }).done(function(data) {
        $("#notifying_unit_address_attributes_street").val(data['logradouro'])

        
        $('#notifying_unit_address_attributes_neighborhood_id option')
            .filter(function(index) { return $(this).text() === data['bairro'];
         }).attr('selected', true);

        $('#notifying_unit_address_attributes_neighborhood_id').trigger("chosen:updated");

        
        $('#notifying_unit_address_attributes_county_id option')
            .filter(function(index) { return $(this).text() === data['localidade'];
         }).attr('selected', true);

        $('#notifying_unit_address_attributes_county_id').trigger("chosen:updated");


        $('#notifying_unit_address_attributes_federative_unit_id option')
            .filter(function(index) { return $(this).text() === data['uf'] })
            .attr('selected', true);

        $('#notifying_unit_address_attributes_federative_unit_id').trigger("chosen:updated");

      }).fail(function() {

      });
    }
  });
});

$(function () {
    $("input[id=primary_care_notification_address_attributes_zip_code]").keyup(function() {
        var cep=$(this).val()
        var url = "/utils/search-cep?cep=" + cep;
        if (cep.length >= 6){
            $.getJSON(url, function( data ) {
            }).done(function(data) {
                $("#primary_care_notification_address_attributes_street").val(data['logradouro'])


                $('#primary_care_notification_address_attributes_neighborhood_id option')
                    .filter(function(index) { return $(this).text() === data['bairro'];
                    }).attr('selected', true);

                $('#primary_care_notification_address_attributes_neighborhood_id').trigger("chosen:updated");


                $('#primary_care_notification_address_attributes_county_id option')
                    .filter(function(index) { return $(this).text() === data['localidade'];
                    }).attr('selected', true);

                $('#primary_care_notification_address_attributes_county_id').trigger("chosen:updated");


                $('#primary_care_notification_address_attributes_federative_unit_id option')
                    .filter(function(index) { return $(this).text() === data['uf'] })
                    .attr('selected', true);

                $('#primary_care_notification_address_attributes_federative_unit_id').trigger("chosen:updated");

            }).fail(function() {

            });
        }
    });
});
$(function () {
    $(".input_citizen_address_zip_code").keyup(function() {
        var cep=$(this).val()
        var url = "/utils/search-cep?cep=" + cep;
        if (cep.length >= 6){
            $.getJSON(url, function( data ) {
            }).done(function(data) {
                $(".input_citizen_address_street").val(data['logradouro'])


                $('.input_citizen_address_neighborhood option')
                    .filter(function(index) { return $(this).text() === data['bairro'];
                    }).attr('selected', true);

                $('.input_citizen_address_neighborhood').trigger("chosen:updated");


                $('.input_citizen_address_county option')
                    .filter(function(index) { return $(this).text() === data['localidade'];
                    }).attr('selected', true);

                $('.input_citizen_address_county').trigger("chosen:updated");


                $('.input_citizen_address_federative_unit option')
                    .filter(function(index) { return $(this).text() === data['uf'] })
                    .attr('selected', true);

                $('.input_citizen_address_federative_unit').trigger("chosen:updated");

            }).fail(function() {

            });
        }
    });
});

$(function () {
    $(".input_shared_address_zip_code").keyup(function() {
        var cep=$(this).val()
        var url = "/utils/search-cep?cep=" + cep;
        if (cep.length >= 6){
            $.getJSON(url, function( data ) {
            }).done(function(data) {
                $(".input_shared_address_street").val(data['logradouro'])


                $('.input_shared_address_neighborhood option')
                    .filter(function(index) { return $(this).text() === data['bairro'];
                    }).attr('selected', true);

                $('.input_shared_address_neighborhood').trigger("chosen:updated");


                $('.input_shared_address_county option')
                    .filter(function(index) { return $(this).text() === data['localidade'];
                    }).attr('selected', true);

                $('.input_shared_address_county').trigger("chosen:updated");


                $('.input_shared_address_federative_unit option')
                    .filter(function(index) { return $(this).text() === data['uf'] })
                    .attr('selected', true);

                $('.input_shared_address_federative_unit').trigger("chosen:updated");

            }).fail(function() {

            });
        }
    });
});

$(function () {
  $(".input_citizen_address_zip_code").mask('00000-000');
  $(".input_shared_address_zip_code").mask('00000-000');
  $("#patient_record_address_attributes_zip_code").mask('00000-000');
  $("#notifying_unit_address_attributes_zip_code").mask('00000-000');
  $("#primary_care_notification_address_attributes_zip_code").mask('00000-000');
});