import {calculate_age, test_cpf} from "./functions_shared";

$(function () {
    handle_call_center_answered_call_change();
    handle_call_center_has_condictions_change();
    handle_call_center_symptomatic_patient_change();
    //---------------- calcular idade primary care notification -----------------
    $("#call_center_ub_citizen_attributes_birth_date").change(function () {
        if (this.value !== "") {
            $("#call_center_ub_age").val(calculate_age(this.value, $("#call_center_ub_call_center_date").val())).prop("readonly", true)
        } else {
            $("#call_center_ub_age").prop("readonly", false).val("")
        }
    });

    //------------ Verifica se o paciente atendeu o chamado ------------
    $('#call_center_ub_answered_call').change(function () {
        handle_call_center_answered_call_change()
    });


    $('#call_center_ub_has_condictions').change(function () {
        handle_call_center_has_condictions_change()
    });
    $('#call_center_ub_symptomatic_patient').change(function () {
        handle_call_center_symptomatic_patient_change()
    });
});

function handle_call_center_answered_call_change() {
    if ($('#call_center_ub_answered_call').is(':checked')) {
        $('.clinic_info').show();
    } else {
        $('.clinic_info').hide();
        $('#call_center_ub_has_condictions').val("NAO INFORMADO");
        $('#call_center_ub_symptomatic_patient').val("NAO INFORMADO");
    }
}

function handle_call_center_has_condictions_change() {
    let  call_center_has_condictions = $('#call_center_ub_has_condictions').val();
    if (call_center_has_condictions == "SIM") {
        $(".call_center_ub_condictions").show()
    } else {
        $(".call_center_ub_condictions").hide();
    }
}
function handle_call_center_symptomatic_patient_change() {
    let call_center_symptomatic_patient = $('#call_center_ub_symptomatic_patient').val();
    if (call_center_symptomatic_patient == "SIM") {
        $(".call_center_ub_symptoms").show()
    } else {
        $(".call_center_ub_symptoms").hide()
    }
}


