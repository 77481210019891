// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("popper.js")
require("bootstrap")
require("chosen-js")
require("patient_record")
require("primary_care_notification")
require("citizen")
require("fast_test")
require("call_center")
require("total_bed")
require('search_cep')
require('datatables.net');
require('datatables.net-bs4');
require('datatables.net-buttons');
require('datatables.net-buttons-bs4');
import 'jquery-mask-plugin/dist/jquery.mask.min';

import jszip from 'jszip';
import 'datatables.net-buttons/js/buttons.html5';
import 'chosen-js/chosen';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).ready(function () {

    $('a[data-toggle="tab"]').click(function (e) {
        e.preventDefault();
        $(this).tab('show');
    });

    $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
        var id = $(e.target).attr("href");
        localStorage.setItem('selectedTab', id)
    });

    var selectedTab = localStorage.getItem('selectedTab');
    if (selectedTab != null) {
        $('a[data-toggle="tab"][href="' + selectedTab + '"]').tab('show');
    }
  $.fn.dataTable.Buttons.jszip( jszip );

  $('.phone_with_ddd').mask('(00) 00000-0000', {placeholder: '(00)00000-0000'});
  $('.numbers_only').mask('999');
  $('.cpf').mask('999.999.999-99');
  $('.cns').mask('99999999999999');
  $('.cnpj').mask('99.999.999/9999-99');

  $('.data-table').dataTable({
      language: {"url": "//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json"},
      order: [[ 0, 'asc' ]],
      searching: false,
      lengthChange: true,
      stateSave: true,
      dom: "<'row'<'col-sm-12 col-md-6 mb-3'B><'col-sm-12 col-md-6'>>"+
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
            'copy', 'csv', 'excel'
        ]
  });
    $('.data-table-without-pagination').dataTable({
        language: {"url": "//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json"},
        order: [[ 0, 'asc' ]],
        searching: false,
        paging: false,
        lengthChange: true,
        stateSave: true
    });

  $('#establishments_survey_table').dataTable({
      language: {"url": "//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json"},
      order: [[ 0, 'desc' ]],
      searching: true,
      lengthChange: true,
      dom: "<'row'<'col-sm-12 col-md-6 mb-3'B><'col-sm-12 col-md-6'>>"+
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'>>" +
            "<'row'<'col-sm-12 overflow-auto'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
          'copy', 'csv', 'excel'
      ],
      initComplete: function(){
        this.api().columns('.searchable').every( function () {
          var column = this;
          var select = $('<select><option value=""></option></select>')
          .appendTo( $(column.footer()).empty() )
          .on( 'change', function () {
            var val = $.fn.dataTable.util.escapeRegex(
              $(this).val()
            );

            column
            .search( val ? '^'+val+'$' : '', true, false )
            .draw();
          } );

          column.data().unique().sort().each( function ( d, j ) {
            var val = $('<div/>').html(d).text();
            select.append( '<option value="' + val + '">' + val + '</option>' );
          } );
        } );
      }
  });

  // enable chosen js
  $('.chosen-select').chosen({
    allow_single_deselect: true,
    no_results_text: 'Nenhum resultado corresponde',
    placeholder_text_single: 'Selecione um item',
    placeholder_text_multiple: 'Selecione',
    width: '100%'
  });

  $("#total_beds_table th").addClass("text-nowrap")
  $("#consolidated_notifications_table th").addClass("text-nowrap")

  // use class to avoid code repeat
  $('.data-table-conv').dataTable({
      language: {"url": "//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json"},
      order: [[ 0, 'asc' ]],
      searching: false,
      lengthChange: true,
      scrollX: true,
      dom: "<'row'<'col-sm-12 col-md-6 mb-3'B><'col-sm-12 col-md-6'>>"+
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
            'copy', 'csv', 'excel'
        ]
  });

  //Use tooltips application
  $('[data-toggle="tooltip"]').tooltip();
});
