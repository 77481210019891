import {calculate_age} from "./functions_shared";

$(function(){
  $('#patient_record_address_attributes_county_id').on( 'change', function () {
      if ($("#patient_record_address_attributes_county_id option:selected").html() != 'TERESINA'){
        $('.patient_record_address_neighborhood').hide();
          $('#patient_record_address_attributes_neighborhood_id').val('').trigger("chosen:updated");
      }else{
        $('.patient_record_address_neighborhood').show()
      }
  });

  $("#patient_record_nu_federative_unit").on( 'change', function(evt){
    filter_counties_by_fu(this.value);
  })

  $("#patient_record_address_attributes_federative_unit_id").on( 'change', function(evt){
    filter_address_counties_by_fu(this.value);
  })

  $("#patient_record_nu_county").on( 'change', function(evt){
    filter_notifying_unit_by_county(this.value);
  })

  handle_patient_record_status_change();
  $("#patient_record_status").on( 'change', function(){
    handle_patient_record_status_change();
  })

  // When other symptom is checked
  handle_patient_record_symptom_others();
  $("#patient_record_symptom_ids_7").change(function(){
    handle_patient_record_symptom_others();
  });

  // When other risk_factor is checked
  handle_patient_record_risk_factor_others();
  $("#patient_record_risk_factor_ids_32").change(function(){
    handle_patient_record_risk_factor_others();
  });

  handle_patient_record_exam_collection_change();
  $("#patient_record_exam_collection").on( 'change', function(){handle_patient_record_exam_collection_change()});

  handle_exam_situation_change();
  $("#patient_record_exam_attributes_situation").on( 'change', function(){handle_exam_situation_change()});

  handle_has_risk_factors_change();
  $("#patient_record_has_risk_factors").on( 'change', function(){handle_has_risk_factors_change()});

  handle_symptomatic_patient_change();
  $("#patient_record_symptomatic_patient").on( 'change', function(){handle_symptomatic_patient_change()});

  handle_patient_record_hospitalization_change();
  $("#patient_record_hospitalization").on( 'change', function(){handle_patient_record_hospitalization_change()});

  $("#patient_record_notification_date").on( 'change', function() {
    $("#patient_record_birth_date").trigger("change")
  });

  $("#patient_record_birth_date").on( 'change', function(){
      if (this.value !== "") {
          $("#patient_record_age").val(calculate_age(this.value, $("#patient_record_notification_date").val())).prop("readonly", true)
      }else{
          $("#patient_record_age").prop("readonly", false).val("")
      }
  });
  if ($("#patient_record_birth_date").val() !== '') {
      $("#patient_record_age").prop("readonly", true)
  }

  $("#patient_record_nil_cpf_confirmation").on( 'change', function() {
    let is_confirmed = false;
    if (this.checked) {
      is_confirmed = confirm("Não informar o CPF do paciente pode causar a inclusão de registros duplicados no sistema. Deseja confirmar o registro sem CPF?");
      $("#patient_record_cpf").val('');
      $("#patient_record_cpf").prop('readonly', true);
    }else{
      $("#patient_record_cpf").prop('readonly', false);
    }
    this.checked = is_confirmed;
  })
})

function handle_has_risk_factors_change(){
  let has_risk_factors = $("#patient_record_has_risk_factors").val();

  if (has_risk_factors === "SIM") {
    $(".patient_record_risk_factors").show();
  }else {
    clear_risk_factor_fields();
    $(".patient_record_risk_factors").hide();
  }
}

function clear_risk_factor_fields(){
  $(".patient_record_risk_factors input[type=checkbox]").prop("checked", false)
}

function handle_symptomatic_patient_change(){
  let symptomatic_patient = $("#patient_record_symptomatic_patient").val();

  if (symptomatic_patient === "SIM") {
    $(".patient_record_first_symptoms_date").show();
    $(".patient_record_symptoms").show();
  }else {
    clear_symptomatic_patient_fields();
    $(".patient_record_first_symptoms_date").hide();
    $(".patient_record_symptoms").hide();
  }
}

function clear_symptomatic_patient_fields(){
  $("#patient_record_first_symptoms_date").val('')
  $(".patient_record_symptoms input[type=checkbox]").prop("checked", false)
}

function handle_exam_situation_change(){
  let exam_situation = $("#patient_record_exam_attributes_situation").val();

  if (exam_situation === "finished") {
    $(".patient_record_exam_result_date").show()
    $(".patient_record_exam_exam_results").show()
  }else {
    $(".patient_record_exam_result_date").hide()
    $(".patient_record_exam_exam_results").hide()
    clear_exam_results();
  }
}

function clear_exam_results(){
  $("#patient_record_exam_attributes_result_date").val('')
  $(".patient_record_exam_exam_results input[type=checkbox]").prop("checked", false)
}

function handle_patient_record_exam_collection_change(){
  let patient_record_exam_collection = $("#patient_record_exam_collection").val();
  if (patient_record_exam_collection === "SIM") {
    $(".patient_record_exam_kind_test").show();
    $(".patient_record_exam_collection_date").show();
    $(".patient_record_exam_situation").show();
  }else {
    clear_exam_fields();
    $(".patient_record_exam_kind_test").hide();
    $(".patient_record_exam_collection_date").hide();
    $(".patient_record_exam_situation").hide();
    $(".patient_record_exam_result_date").hide();
    $(".patient_record_exam_exam_results").hide();
  }
}

function handle_patient_record_hospitalization_change(){
  let patient_record_hospitalization = $("#patient_record_hospitalization").val();
  if (patient_record_hospitalization === "SIM") {
    $(".patient_record_discharge_date").show();
    $(".patient_record_icu_hospitalization").show();
    $(".patient_record_respiratory_support").show();
  } else {
    clear_hospitalization();
    $(".patient_record_discharge_date").hide();
    $(".patient_record_icu_hospitalization").hide();
    $(".patient_record_respiratory_support").hide();
  }
}

function clear_hospitalization(){
  $("#patient_record_discharge_date").val('');
  $("#patient_record_icu_hospitalization").val('');
  $("#patient_record_respiratory_support").val('');
  $("#patient_record_icu_hospitalization").trigger("chosen:updated");
  $("#patient_record_respiratory_support").trigger("chosen:updated");
}

function clear_exam_fields(){
  $("#patient_record_exam_attributes_collection_date").val('');
  $("#patient_record_exam_attributes_situation").val('');
  $("#patient_record_exam_attributes_situation").trigger("chosen:updated");
  clear_exam_results();
}
// death_by_covid19
function handle_patient_record_status_change(){
  let patient_record_status = $("#patient_record_status").val()
  if (patient_record_status === "discharged") {
    $("#patient_record_death_date").val('');
    $(".patient_record_death_date").hide();
    $("#patient_record_death_by_covid19").prop("checked", false);
    $(".patient_record_death_by_covid19").hide();
  }else if (patient_record_status === "dead") {
    $(".patient_record_death_date").show();
    $(".patient_record_death_by_covid19").show();
  }else {
    $("#patient_record_death_date").val('');
    $(".patient_record_death_date").hide();
    $("#patient_record_death_by_covid19").prop("checked", false);
    $(".patient_record_death_by_covid19").hide();
  };
}

// Manage other_symptom changed
function handle_patient_record_symptom_others(){
  let other_checkbox = $("#patient_record_symptom_ids_7").prop("checked")
  if (other_checkbox === true) {
    $(".patient_record_other_symptom").show();
  }else {
    $("#patient_record_other_symptom").val('');
    $(".patient_record_other_symptom").hide();
  };
}

// Manage other_symptom changed
function handle_patient_record_risk_factor_others(){
  let other_checkbox = $("#patient_record_risk_factor_ids_32").prop("checked")
  if (other_checkbox === true) {
    $(".patient_record_other_risk_factor").show();
  }else {
    $("#patient_record_other_risk_factor").val('');
    $(".patient_record_other_risk_factor").hide();
  };
}

function filter_counties_by_fu(federative_unit){
  $.get("/filter_counties_by_fu", {federative_unit: federative_unit}, "json")
    .done(function(data){
      //populate notifying units select
      $("#patient_record_nu_county")
      .find('option')
      .remove()
      .end();
      $("#patient_record_nu_county")
        .append(`<option value = ''></option>`)
      data.forEach((county, i) => {
        $("#patient_record_nu_county")
        .append(`<option value = ${county.id}>${county.name}</option>`)
      });
      $("#patient_record_nu_county").trigger('chosen:updated');
    });
}

function filter_address_counties_by_fu(federative_unit){
  $.get("/filter_counties_by_fu", {federative_unit: federative_unit}, "json")
    .done(function(data){
      //populate address counties select
      $("#patient_record_address_attributes_county_id")
      .find('option')
      .remove()
      .end();
      $("#patient_record_address_attributes_county_id")
        .append(`<option value = ''></option>`)
      data.forEach((county, i) => {
        $("#patient_record_address_attributes_county_id")
        .append(`<option value = ${county.id}>${county.name}</option>`)
      });
      $("#patient_record_address_attributes_county_id").trigger('chosen:updated');
    });
}

function filter_notifying_unit_by_county(county_id){
  $.get("/filter_notifying_unit_by_county", {county_id: county_id }, "json")
    .done(function(data){
      $("#patient_record_notifying_unit_id")
      .find('option')
      .remove()
      .end();
      $("#patient_record_notifying_unit_id")
        .append(`<option value = ''></option>`)
      data.forEach((unit, i) => {
        $("#patient_record_notifying_unit_id")
        .append(`<option value = ${unit.id}>${unit.trade_name}</option>`)
      });
      $("#patient_record_notifying_unit_id").trigger('chosen:updated');
    });
}

function handle_select_all_check(e){
  console.log(e.target.checked);
  $(".selectable").prop("checked", e.target.checked);
}
$(function(){
  $('#select_all_patient_record').on( 'change', function(evt){
    handle_select_all_check(evt);
  });
});
$(function() {
  $('.patient_record_tables').dataTable({
    language: {"url": "//cdn.datatables.net/plug-ins/1.10.19/i18n/Portuguese-Brasil.json"},
    searching: true,
    lengthChange: true,
    stateSave: true,
    processing: true,
    fixedHeader: true,
    orderCellsTop: true,
    dom: "<'row'<'col-sm-12 col-md-6 mb-3'B><'col-sm-12 col-md-6'>>"+
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
    buttons: [
        'copy', 'csv', 'excel'
    ],
    initComplete: function(){
      this.api().columns('.searchable').every( function () {
        var column = this;
        var select = $('<select><option value=""></option></select>')
        .appendTo( $(column.footer()).empty() )
        .on( 'change', function () {
          var val = $.fn.dataTable.util.escapeRegex(
            $(this).val()
          );

          column
          .search( val ? '^'+val+'$' : '', true, false )
          .draw();
        } )
        .chosen({
          allow_single_deselect: true,
          no_results_text: 'Nenhum resultado corresponde',
          placeholder_text_single: 'Selecione um item',
          placeholder_text_multiple: 'Selecione',
          width: '100%'
          });
        column.data().unique().sort().each( function ( d, j ) {
          var val = $('<div/>').html(d).text();
          if (column.search() === "^" + val + "$") {
            select.append( '<option value="' + val + '" selected="selected">' + val + '</option>' )
            .trigger('chosen:updated');
          }else{
            select.append( '<option value="' + val + '">' + val + '</option>' )
            .trigger('chosen:updated');
          }
        });
      });
    }
  })
});

$(function() {
  $("#patient_record_address_attributes_zip_code").mask('00000-000');
  $("#notifying_unit_address_attributes_zip_code").mask('00000-000');
});

// Confirm death by COVID-19
jQuery(function(){
  $('#patient_record_death_by_covid19').on('click', function() {
    if (this.checked) {
      if (confirm('Você tem certeza?')) {
        this.checked = true;
      }else{
        this.checked = false;
      }
    }
  });
});
