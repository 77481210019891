$(function() {
    $("#total_bed_total_beds_stabilization").change(function(evt){
        $("#total_bed_total_beds_stabilization_vacant").val(this.value - $("#total_bed_total_beds_stabilization_occupieds").val());
        $("#total_bed_occupancy_rate").val(($("#total_bed_total_beds_stabilization_occupieds").val()*100/this.value).toFixed(2));
    })
    $("#total_bed_total_beds_stabilization_occupieds").change(function(evt){
        $("#total_bed_total_beds_stabilization_vacant").val($("#total_bed_total_beds_stabilization").val() - this.value);
        $("#total_bed_occupancy_rate").val((this.value*100/$("#total_bed_total_beds_stabilization").val()).toFixed(2));
    })
    $("#total_bed_total_beds_icu").change(function(evt){
        $("#total_bed_total_beds_icu_vacant").val(this.value - $("#total_bed_total_beds_icu_occupieds").val());
        $("#total_bed_occupancy_rate_icu").val(($("#total_bed_total_beds_icu_occupieds").val()*100/this.value).toFixed(2));
    })
    $("#total_bed_total_beds_icu_occupieds").change(function(evt){
        $("#total_bed_total_beds_icu_vacant").val($("#total_bed_total_beds_icu").val() - this.value);
        $("#total_bed_occupancy_rate_icu").val((this.value*100/$("#total_bed_total_beds_icu").val()).toFixed(2));
    })
});