 export function calculate_age(date_of_birth, date_of_notification) {
        var date_notification = (date_of_notification) ? new Date(date_of_notification) : new Date();
        var date_birth = new Date(date_of_birth);
        var age = date_notification.getUTCFullYear() - date_birth.getUTCFullYear()
        if (date_notification.getUTCMonth() < date_birth.getUTCMonth()) {
            age -= 1
        } else if (date_notification.getUTCMonth() == date_birth.getUTCMonth() && date_notification.getUTCDate() < date_birth.getUTCDate()) {
            age -= 1
        }
        return age
    }
 export function test_cpf(strCPF) {
     let Sum;
     let Rest;
     Sum = 0;
     if (strCPF == "00000000000") return false;

     for (let i=1; i<=9; i++) Sum = Sum + parseInt(strCPF.substring(i-1, i)) * (11 - i);
     Rest = (Sum * 10) % 11;

     if ((Rest == 10) || (Rest == 11))  Rest = 0;
     if (Rest != parseInt(strCPF.substring(9, 10)) ) return false;

     Sum = 0;
     for (let i = 1; i <= 10; i++) Sum = Sum + parseInt(strCPF.substring(i-1, i)) * (12 - i);
     Rest = (Sum * 10) % 11;

     if ((Rest == 10) || (Rest == 11))  Rest = 0;
     if (Rest != parseInt(strCPF.substring(10, 11) ) ) return false;
     return true;
 }
 export function validar_cns(cns) {
    if (cns.length > 0) {
        var validaTamanho = cns.length == 15;
        var validaInicio = ['1', '2', '7', '8', '9'].includes(cns[0]);

        if (validaTamanho && validaInicio) {
            //CNS Iniciados em 7, 8 ou 9
            if (['7', '8', '9'].includes(cns[0])) {
                var soma = cns.split('').reduce((total, value, index) => total + (value * (15 - index)), 0);
                return soma % 11 == 0;
            } else {
                //CNS Iniciados em 1, 2
                var pis = cns.substring(0, 11);
                var soma = pis.split('').reduce((total, value, index) => total + (value * (15 - index)), 0);

                var resto = soma % 11;
                var dv = resto == 0 ? 0 : 11 - resto;

                var resultado = dv == 10 ? `${pis}001${(11 - ((soma + 2) % 11))}` : `${pis}000${dv}`
                return resultado == cns;
            }
        }

        return false;
    }else{
        return true;
    }
 }