import {calculate_age, test_cpf} from "./functions_shared";

$(function () {
    handle_primary_care_notification_foreign_change();
    handle_primary_care_notification_test_change();
    handle_primary_care_notification_has_condictions_change();
    handle_primary_care_notification_symptomatic_patient_change();
    //---------------- calcular idade primary care notification -----------------
    $("#primary_care_notification_citizen_birth_date").change(function () {
        if (this.value !== "") {
            $("#primary_care_notification_age").val(calculate_age(this.value, $("#primary_care_notification_notification_date").val())).prop("readonly", true)
        } else {
            $("#primary_care_notification_age").prop("readonly", false).val("")
        }
    });

    //------------ Verifica se o paciente é extrangeiro ------------
    $('#primary_care_notification_foreign').change(function () {
        handle_primary_care_notification_foreign_change()
    });

    $('#primary_care_notification_cpf').change(function () {
        if (!test_cpf(this.value.replace(/[^0-9]/g, ""))){
            alert('O CPF está incorreto!')
        }
    });
    $('#primary_care_notification_has_condictions').change(function () {
        handle_primary_care_notification_has_condictions_change()
    });
    $('#primary_care_notification_symptomatic_patient').change(function () {
        handle_primary_care_notification_symptomatic_patient_change()
    });
    $('#primary_care_notification_test_attributes_status_test').change(function () {
        handle_primary_care_notification_test_change()
    });
    $('#submit_search_cpf_cns').click(function () {
        $.ajax({
            url: '/load_resources/search_cpf_cns',
            type: 'GET',
            dataType: 'script',
            data: {q: {cpf_or_cns_cont:$("#q_cpf_or_cns_cont").val()}},
            success: function(data) {
                data = JSON.parse(data);
                if (data){
                   $('.input_shared_cpf').val(data.cpf);
                   $('#q_cpf_or_cns_cont').addClass('is-valid');
                   $('.input_shared_cns').val(data.cns);
                   $('.input_shared_mother_name').val(data.mother_name);
                   $('.input_shared_birth_date').val(data.birth_date);
                   $('.input_shared_name').val(data.name);
                   $('.input_shared_telephone').val(data.telephone);
                   $('.input_shared_sex').val(data.sex).trigger("chosen:updated");
                   $('.input_shared_citizen_id').val(data.id);
                   if (data.address) {
                       $('.input_shared_address_zip_code').val(data.address.zip_code);
                       $('.input_shared_address_street').val(data.address.street);
                       $('.input_shared_address_number').val(data.address.number);
                       $('.input_shared_address_complement').val(data.address.complement);
                       $('.input_shared_address_federative_unit').val(data.address.federative_unit_id).trigger("chosen:updated");
                       $('.input_shared_address_neighborhood').val(data.address.neighborhood_id).trigger("chosen:updated");
                       $('.input_shared_address_county').val(data.address.county_id).trigger("chosen:updated");
                   }else{
                       $('.input_shared_address_zip_code').val('');
                       $('.input_shared_address_street').val('');
                       $('.input_shared_address_number').val('');
                       $('.input_shared_address_complement').val('');
                       $('.input_shared_address_federative_unit').val('').trigger("chosen:updated");
                       $('.input_shared_address_neighborhood').val('').trigger("chosen:updated");
                       $('.input_shared_address_county').val('').trigger("chosen:updated");
                   }
                    if (data.birth_date !== "") {
                        $(".age").val(calculate_age(data.birth_date, $(".notification_date").val())).prop("readonly", true)
                    } else {
                        $(".age").prop("readonly", false).val("")
                    }
                }
                else{
                   alert('Paciente não encontrado no sistema! por favor adicione-o.')
                }
            }
        })
    })
});

function handle_primary_care_notification_foreign_change() {
    if ($('#primary_care_notification_foreign').is(':checked')) {
        $('.primary_care_notification_passport').show();
        $('.primary_care_notification_country').show();
    } else {
        $('.primary_care_notification_passport').hide();
        $('#primary_care_notification_passport').val('');
        $('.primary_care_notification_country').hide('');
        $('#primary_care_notification_country').val('').trigger("chosen:updated");
    }
}

function handle_primary_care_notification_test_change() {
    let NOT_INFORMED = 0;
    let primary_care_notification_test = $('#primary_care_notification_test_attributes_status_test').val();
    if (primary_care_notification_test == "collected" || primary_care_notification_test == "finished") {
        $(".primary_care_notification_test_collection_date").show()
        $(".primary_care_notification_test_test_result").show()
        $(".primary_care_notification_final_classification").show()
        $('#primary_care_notification_test_attributes_kind_test_id').val('').trigger("chosen:updated");
    } else {
        $(".primary_care_notification_test_collection_date").hide()
        $(".primary_care_notification_test_test_result").hide()
        $(".primary_care_notification_final_classification").hide()
        $('#primary_care_notification_test_attributes_kind_test_id').val(NOT_INFORMED).trigger("chosen:updated");
    }
}
function handle_primary_care_notification_has_condictions_change() {
    let  primary_care_notification_has_condictions = $('#primary_care_notification_has_condictions').val();
    if (primary_care_notification_has_condictions == "SIM") {
        $(".primary_care_notification_condictions").show()
    } else {
        $(".primary_care_notification_condictions").hide();
    }
}
function handle_primary_care_notification_symptomatic_patient_change() {
    let primary_care_notification_symptomatic_patient = $('#primary_care_notification_symptomatic_patient').val();
    if (primary_care_notification_symptomatic_patient == "SIM") {
        $(".primary_care_notification_first_symptoms_date").show()
        $(".primary_care_notification_symptoms").show()
    } else {
        $(".primary_care_notification_first_symptoms_date").hide()
        $(".primary_care_notification_symptoms").hide()
    }
}


