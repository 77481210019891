
$(function () {

    handle_fast_test_test_change();
    handle_fast_test_has_condictions_change();
    handle_fast_test_symptomatic_patient_change();

    $('#fast_test_has_condictions').change(function () {
        handle_fast_test_has_condictions_change()
    });
    $('#fast_test_symptomatic_patient').change(function () {
        handle_fast_test_symptomatic_patient_change()
    });
    $('#fast_test_test_attributes_status_test').change(function () {
        handle_fast_test_test_change()
    });
});
function handle_fast_test_test_change() {
    let NOT_INFORMED = 0;
    let fast_test_test = $('#fast_test_test_attributes_status_test').val();
    if (fast_test_test == "collected" || fast_test_test == "finished") {
        $(".fast_test_test_collection_date").show()
        $(".fast_test_test_test_result").show()
        $(".fast_test_final_classification").show()
        $('#fast_test_test_attributes_kind_test_id').val('').trigger("chosen:updated");
    } else {
        $(".fast_test_test_collection_date").hide()
        $(".fast_test_test_test_result").hide()
        $(".fast_test_final_classification").hide()
        $('#fast_test_test_attributes_kind_test_id').val(NOT_INFORMED).trigger("chosen:updated");
    }
}
function handle_fast_test_has_condictions_change() {
    let  fast_test_has_condictions = $('#fast_test_has_condictions').val();
    if (fast_test_has_condictions == "SIM") {
        $(".fast_test_condictions").show()
    } else {
        $(".fast_test_condictions").hide();
    }
}
function handle_fast_test_symptomatic_patient_change() {
    let fast_test_symptomatic_patient = $('#fast_test_symptomatic_patient').val();
    if (fast_test_symptomatic_patient == "SIM") {
        $(".fast_test_first_symptoms_date").show()
        $(".fast_test_symptoms").show()
    } else {
        $(".fast_test_first_symptoms_date").hide()
        $(".fast_test_symptoms").hide()
    }
}